<!-- 试题详情 未作答 -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';

.page{
	height: 100%;
    position: relative;
	width: 100%;
    height: 100%;
    box-sizing: border-box;
	padding-bottom: 80px;
}
.content{
	height:100%;
	overflow-y: auto;
	
	.showAnswer{
		position: absolute;
		right: 30px;
		top: 15px;
		width: 50px;
		font-size: 22px;
		&:hover{
			opacity: 0.8;
			cursor: pointer;
		}
	}
	.questions{
		padding-top: 30px;
		.li{
			box-sizing: border-box;
			cursor: pointer;
			.selectimg{
				max-width: 97%;
			}
		}
	}
}

// 小题列表
.answerPage{
	width: 100%;
	height: 600px;
	max-height: 80vh;
	position: relative;
	overflow: auto;
	.backimg{
		width: 100%;
		position: fixed;
		right: 0;
		top: 0;
		height: 100vh;
		z-index: 0;
	}
	.topicList{
		position: relative;
		z-index: 5;
		.paperName{
            font-size: 18px;
            font-weight: 400;
            color: #FF4D4F;
            line-height: 34px;
            width: 100%;
            text-align: center;
            margin: 10px 0;
		}
		.questions{
			width: 100%;
			background-color: #FFFFFF;
			margin-bottom: 32px;
			padding: 0px 6px 39px 32px;
			display: inline-block;
			overflow-x: hidden;
			border-radius: 14px;
			box-sizing: border-box;
			.type{
				font-size: 32px;
				font-weight: bold;
				color: #222222;
				line-height: 45px;
			}
			
		}
		.ul{
			width: 100%;
			display: inline-block;
			.item{
				float: left;
                margin-top: 20px;
                width: 34px;
                height: 34px;
                background: #E2E2E2;
                border-radius: 50%;
                margin-right: 26px;
                text-align: center;
                line-height: 34px;
                color: #FFFFFF;
                font-size: 18px;
                position: relative;
				&.pass{
					background-color: $comGreen;
				}
				&.opt::after{
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 37px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-left: -5px;
					background-color: $comBlue;
				}
				&:hover{
                    cursor: pointer;
					background-color: $comBlue;
				}
                
			}
		}
	}
	.flotageBtn{
		background-color: rgba(255,255,255,0.8);
	}
}

.showAnswer{
	position: absolute;
    right: 30px;
    top: 15px;
    width: 50px;
    font-size: 22px;
	&:hover{
		opacity: 0.8;
		cursor: pointer;
	}
}

.pictureList{
	width: 100%;
	padding: 0px 0 10px;
	display: inline-block;
	.item{
		width: 140px;
		height: 140px;
		border-radius: 8px;
		position: relative;
		margin-left: 15px;
		float: left;
		overflow: hidden;
		.img{
			width: 140px;
			height: 140px;
		}
		.iconfont{
			position: absolute;
			right: 0;
			top:0;
			font-size: 26px;
			opacity: 0.5;
			color: #FFFFFF;
			&:hover{
				cursor: pointer;
				opacity: 1;
			}
		}
		&:first-child{
			margin-left: 0;
		}
	}
}

.picture{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 150px;
    margin: 0 auto;
	::v-deep .el-upload--text{
		height: 56px !important;
	}
	i{
		font-size: 26px;
		margin-right: 10px;
	}
}
</style>

<template>
	<div class="page">
		<div class="content">
			<div class="questions" v-if="homeworkItem.syque_typ_name">
				<div class="type flex_align">
					{{homeworkItem.syque_typ_name}} 
				</div>
				<div class="topic" v-if="homeworkItem.syque_typ_id!=14">
					<div class="val">
						<div class="richinline" v-html="homeworkItem.sthom_que_title"></div>
					</div>
					<img class="selectimg" v-if="homeworkItem.sthom_que_image" :src="homeworkItem.sthom_que_image">
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<!-- 单选和判断 -->
				<template v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
						<div class="li" :class="getopt(item.sthom_que_ite_code,homeworkItem.sthom_que_stuanswer)?'opt':''" @click="clickPap(index,item.sthom_que_ite_code)">
							<template v-if="item.sthom_que_ite_title">
								<div class="flex">
									<div class="number">{{item.sthom_que_ite_code}}.</div>
									<div class="val"><span class="richinline" v-html="item.sthom_que_ite_title"></span></div>
								</div>
								<img class="selectimg" v-if="item.sthom_que_ite_image" :src="item.sthom_que_ite_image">
							</template>
							<div class="flex" v-else>
								<div class="number">{{item.sthom_que_ite_code}}.</div>
								<img class="selectimg" v-if="item.sthom_que_ite_image" :src="item.sthom_que_ite_image">
							</div>
						</div>
					</div>
				</template>
				<!-- 填空 -->
				<template v-if="homeworkItem.syque_typ_id==3">
					<div v-for="(item,index) in homeworkItem.question_item" :key="item.sthom_que_id">
        				<try-editor :key="item.sthom_que_id+'_'+index" :showEditor="homeworkItem.syque_typ_id==3" class="tinymce" v-model="homeworkItem.sthom_que_stuanswer[index]" placeholder="点击输入"></try-editor>
						<!-- <input type="text" class="input" v-model="homeworkItem.sthom_que_stuanswer[index]" placeholder="点击输入" placeholder-class="placeholder"> -->
					</div>
					<!-- <img class="selectimg" v-if="homeworkItem.sthom_que_stuanswer_image" :src="homeworkItem.sthom_que_stuanswer_image"> -->
				</template>
				<template v-if="homeworkItem.syque_typ_id==4">
					<div class="hint">请依次将填空题答案输入到下框中</div>
					<div v-for="(item,index) in homeworkItem.items" :key="index+'_'+papIndex">
        				<try-editor :key="index+'_'+papIndex" :showEditor="homeworkItem.syque_typ_id==4" class="tinymce" v-model="homeworkItem.sthom_que_stuanswer[index]" placeholder="点击输入"></try-editor>
						<!-- <input type="text" class="input" v-model="homeworkItem.sthom_que_stuanswer[index]" placeholder="点击输入" placeholder-class="placeholder"> -->
					</div>
					<!-- <img v-if="homeworkItem.sthom_que_stuanswer_image" class="selectimg" :src="homeworkItem.sthom_que_stuanswer_image"> -->
				</template>
				<!-- 8问答 -->
				<template v-if="homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					
					<try-editor :showEditor="homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8" class="tinymce" v-model="homeworkItem.sthom_que_stuanswer[0]" placeholder="点击输入"></try-editor>
					<!-- <textarea class="textarea" v-model="homeworkItem.sthom_que_stuanswer[0]" placeholder="点击输入" placeholder-class="placeholder"></textarea> -->
					<!-- <img v-if="homeworkItem.sthom_que_stuanswer_image" class="selectimg" :src="homeworkItem.sthom_que_stuanswer_image"> -->
				</template>
				<!-- 图片上传 -->
				<div v-if="homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					<div class="pictureList" v-if="homeworkItem.sthom_que_stuanswer_image">
						<div class="item">
							<el-image v-if="homeworkItem.sthom_que_stuanswer_image" style="width: 140px; height: 140px" :src="homeworkItem.sthom_que_stuanswer_image" :preview-src-list="[homeworkItem.sthom_que_stuanswer_image]"></el-image>
							<div class="iconfont" @click="deleteImg(1)">&#xe676;</div>
						</div>
					</div>
				</div>
				<!-- <div class="handle flex_bet_align" v-if="homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					<el-upload class="picture" :action="$upload.imgAction" 
						:headers="$upload.header" 
						:show-file-list="false" 
						:accept="$upload.imgAccept" 
						:before-upload="$beforImgUpload" 
						:on-success="(res) =>{return uploadImgSuccess(res,1); }" 
						:on-exceed="() => { $message.warning('上传文件数量超出限制，最多上传1个'); }" 
						multiple 
						ref="imgUploader"
						:limit="1" >
						<div class="combtn6">
							<i class="c-green el-icon-picture"></i>
							<div class="fw-b c-green">上传答案</div>
						</div>
					</el-upload>
				</div> -->
				<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
				<template v-if="homeworkItem.syque_typ_id==14">
					<div class="topic">
						<div class="val">
							<div class="richinline" v-html="homeworkItem.sthom_que_title"></div>
						</div>
						<img class="selectimg" v-if="homeworkItem.sthom_que_image" :src="homeworkItem.sthom_que_image">
					</div>
					<div class="p-0-32">
						<div class="topic">
							<div class="val"> <div class="richinline" v-html="groupworkItem.sthom_que_title"></div>
								<text class="fw-l fs-28 ml-16 c-orange">
								（{{groupworkItem.syque_typ_name}}）
								</text>
							</div>
							<img class="selectimg" v-if="groupworkItem.sthom_que_image" :src="groupworkItem.sthom_que_image">
						</div>
						
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
						<!-- 单选和判断 -->
						<template v-if="groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==2||groupworkItem.syque_typ_id==5">
							<div v-for="(item,index) in groupworkItem.question_item" :key="index">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="getopt(item.sthom_que_ite_code,groupworkItem.sthom_que_stuanswer)?'opt':''" @click="clickMinPap(index,item.sthom_que_ite_code)">
									<template v-if="item.sthom_que_ite_title">
										<div class="flex">
											<div class="number">{{item.sthom_que_ite_code}}.</div>
											<div class="val"><div class="richinline" v-html="item.sthom_que_ite_title"></div></div>
										</div>
										<img class="selectimg" v-if="item.sthom_que_ite_image" :src="item.sthom_que_ite_image">
									</template>
									<div class="flex" v-else>
										<div class="number">{{item.sthom_que_ite_code}}.</div>
										<img class="selectimg" v-if="item.sthom_que_ite_image" :src="item.sthom_que_ite_image">
									</div>
								</div>
							</div>
						</template>
						<!-- 单项填空 -->
						<template v-if="groupworkItem.syque_typ_id==3">
							<div v-for="(item,index) in groupworkItem.question_item" :key="index+'_'+papIndex">
								<try-editor :key="index+'_'+papIndex" :showEditor="groupworkItem.syque_typ_id==3" class="tinymce" v-model="groupworkItem.sthom_que_stuanswer[index]" placeholder="点击输入"></try-editor>
								<!-- <input :key="index" type="text" class="input" v-model="groupworkItem.sthom_que_stuanswer[index]" placeholder="点击输入" placeholder-class="placeholder"> -->
							</div>
							<!-- <img v-if="groupworkItem.sthom_que_stuanswer_image" class="selectimg" :src="groupworkItem.sthom_que_stuanswer_image"> -->
						</template>
						<template v-if="groupworkItem.syque_typ_id==4">
							<div class="hint">请依次将填空题答案输入到下框中</div>
							<div v-for="(item,index) in groupworkItem.items" :key="index+'_'+grouTopicIndex+'_'+papIndex">
								<try-editor :key="index+'_'+grouTopicIndex+'_'+papIndex" :showEditor="groupworkItem.syque_typ_id==4" class="tinymce" v-model="groupworkItem.sthom_que_stuanswer[index]" placeholder="点击输入"></try-editor>
								<!-- <input type="text" class="input" v-model="groupworkItem.sthom_que_stuanswer[index]" placeholder="点击输入" placeholder-class="placeholder"> -->
							</div>
							<!-- <img v-if="groupworkItem.sthom_que_stuanswer_image" class="selectimg" :src="groupworkItem.sthom_que_stuanswer_image"> -->
						</template>
						<!--7计算 8问答 -->
						<template v-if="groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<try-editor :showEditor="groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8" class="tinymce" v-model="groupworkItem.sthom_que_stuanswer[0]" placeholder="点击输入"></try-editor>
							<!-- <textarea class="textarea" v-model="groupworkItem.sthom_que_stuanswer[0]" placeholder="点击输入" placeholder-class="placeholder"></textarea> -->
							<!-- <img v-if="groupworkItem.sthom_que_stuanswer_image" class="selectimg" :src="groupworkItem.sthom_que_stuanswer_image"> -->
						</template>
						
						<!-- 图片上传 -->
						<div v-if="groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<div class="pictureList" v-if="groupworkItem.sthom_que_stuanswer_image">
								<div class="item">
									<el-image v-if="groupworkItem.sthom_que_stuanswer_image" style="width: 140px; height: 140px" :src="groupworkItem.sthom_que_stuanswer_image" :preview-src-list="[groupworkItem.sthom_que_stuanswer_image]"></el-image>
									<div class="iconfont" @click="deleteImg(2)">&#xe676;</div>
								</div>
							</div>
						</div>
						<!-- <div class="handle" v-if="groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<el-upload class="picture" :action="$upload.imgAction" 
								:headers="$upload.header" 
								:show-file-list="false" 
								:accept="$upload.imgAccept" 
								:before-upload="$beforImgUpload" 
								:on-success="(res) =>{return uploadImgSuccess(res,2); }" 
								:on-exceed="() => { $message.warning('上传文件数量超出限制，最多上传1个'); }" 
								ref="imgUploader"
								multiple
								:limit="1" >
								<div class="combtn6">
									<i class="c-green el-icon-picture"></i>
									<div class="fw-b c-green">上传答案</div>
								</div>
							</el-upload>
						</div> -->
						<div class="flex_bet_align groupOperate" style="margin-top:20px;" v-loading="submitloading">
							<div class="combtn3 btn" v-if="grouTopicIndex>0" @click="grouPriorPap">
								<div class="icon iconjiantouzuo1"></div>
								上一题
							</div>
							<div class="combtn4 btn" v-else>
								<div class="icon iconjiantouzuo1"></div>
								上一题
							</div>
							<div class="combtn3 btn" v-if="(grouTopicIndex+1)<homeworkItem.childrenQuestion.length" @click="grouNextPap">
								下一题
								<div class="icon iconjiantouyou"></div>
							</div>
							<div class="combtn4 btn" v-else>
								下一题
								<div class="icon iconjiantouyou"></div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="flotageBtn" v-loading="submitloading">
			<div class="combtn3 mr-24" v-if="itemPapindex>1" @click="priorPap">上一题</div>
			<div class="combtn4 mr-24" v-else>上一题</div>
			<div class="combtn5" v-if="itemPapindex==allPapLength" @click="nextPapSubmit">提交作业</div>
			<div class="combtn3" v-else @click="nextPap">下一题</div>
			<div class="combtn3 showAnswer" @click="showAnswer=true">
				<div class="iconfont">&#xe681;</div>
			</div>
		</div>
		<!-- 显示答题进度 -->
		<el-dialog title="答题目录" :visible.sync="showAnswer" width="50%" center>
			<div class="answerPage">
				<div class="topicList">
					<div class="paperName">点击切换试题</div>
					<div class="questions">
						<div class="ul">
							<div v-for="(item,index) in homework_list" :key="index" class="item" :class="[papIndex==index?'opt':'',getAnswer(item)?'pass':'']" @click="clickQuestion(index)">{{index+1}}</div>
						</div>
					</div>
				</div>
				<div class="flotageBtn flex_content_center">
					<div class="combtn8" @click="showAnswer=false">关闭</div>  
					<div style="width:20px;"></div>
					<div class="combtn3" @click="submitPaper">提交作业</div>
				</div>
			</div>
        </el-dialog>
	</div>
</template>

<script>
import { get_question_list,end_homework,do_answers} from "@api/home"
export default {
	computed: {
	},
    name: 'jobAccomplish',
    data() {
        return {
			// 回到头部按钮距离
			scrollTop: 0,
			//是否提交提示
			showpopup:false,
			//显示小题列表
			showAnswer:false,
			//作业id
			sthom_wor_id:0,
			//所有试题
			homework_list:[],
			//单题内容
			homeworkItem:{},
			//组合题单题内容
			groupworkItem:{},
			//题型下标
			papIndex:0,
			//组合题小题下标
			grouTopicIndex:0,
			//总题数量
			allPapLength:0,
			//当前第几题
			itemPapindex:0,
			//答题结束时间
			endtime:0,
			//按钮等待
			submitloading:false,
			//弹出小题列表
			showAnswer:false,
        }
    },
    created() {
        this.sthom_wor_id=this.$route.params.id
        this.getData();
    },
    methods: {
        /** 获取公开课列表数据 */
        async getData() {
            let { data } = await get_question_list({sthom_wor_id:this.sthom_wor_id});
			//渲染列表
			this.homework_list=data
			//渲染单题
			this.homeworkItem=data[0]
			//判断第一类是否为组合提
			if(data[0].syque_typ_id==14){
				this.groupworkItem=data[0].childrenQuestion[0]
			}
			//判断单题里面是否是组合提
			//获取当前试题进度
			this.getPapNumber()
			console.log(data)
            this.$forceUpdate();
        },
		//获取当前试题进度
		getPapNumber(){
			this.allPapLength=this.homework_list.length
			this.itemPapindex=this.papIndex+1
		},
		//弹出是否退出
		navigateBack(){
			this.showpopup=true
		},
		//确认提交作业
		confirm(){
			this.showpopup=false
			this.submitPaper()
		},
		//提交作业
		async submitPaper(){
			let _this=this
			//点击试题列表直接提交按钮
			this.listSubmit()
			let res = await end_homework({sthom_wor_id:this.sthom_wor_id});
			if(res.status==200){
				this.$alert('作业已提交', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push({
							name:"JOB_INDEX"
						})
					}
				});
			}else{
				this.$message.error('提交失败，请重试');
			}
		},
		//点击试题列表直接提交按钮
		listSubmit(){
			// 处理点击的试题列表直接点击提交 当前题被遗漏未提交
			if(this.homeworkItem.syque_typ_id!=14){
				// 判断最后一题普通类型提交
				this.submitItem(this.homeworkItem);
			}else{
				// 提交组合题答案，并展示出所有题型类表
				this.submitItem(this.groupworkItem);
			}
		},
		//试题列表 点击试题类表里面某个值
		clickQuestion(index){
			// 获取一下点击的当题内容
			// console.log(this.homework_list[index])
			//判断当前的题型
			//不是多选题
			let workItem={}
			if(this.homework_list[this.papIndex].syque_typ_id!=14){
				workItem=this.homeworkItem
			}else{
				workItem=this.groupworkItem
			}
			//提交答案
			this.submitItem(workItem,()=>{
				// 切换之前提交刚刚的表单
				// 提交过后再切换下一题
				this.showAnswer=false
				this.papIndex=index
				this.grouTopicIndex=0
				this.setHomeworkItem(this.homework_list[index])
				// this.homeworkItem=this.homework_list[index]
				// 判断选中的那一题是否是组合提
				if(this.homework_list[index].syque_typ_id==14){
					this.setGroupworkItem(this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex])
				}
				this.getPapNumber()
			});
		},
		//点击单选多选题
		clickPap(index,val){
			let homeworkItem=this.homeworkItem
			if(homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==5){
				//单选题或判断题
				homeworkItem.sthom_que_stuanswer=[val]
			}else if(homeworkItem.syque_typ_id==2){
				//多选题
				if(this.getopt(val,homeworkItem.sthom_que_stuanswer)){
					homeworkItem.sthom_que_stuanswer.splice(homeworkItem.sthom_que_stuanswer.indexOf(val),1)
				}else{
					homeworkItem.sthom_que_stuanswer.push(val)
				}
			}
			this.homework_list[this.papIndex]=homeworkItem
			this.homeworkIte=homeworkItem
			this.$forceUpdate();
		},
		//组合题选中
		clickMinPap(index,val){
			let workItem=this.groupworkItem
			if(workItem.syque_typ_id==1||workItem.syque_typ_id==5){
				//单选题或判断题
				workItem.sthom_que_stuanswer=[val]
			}else if(workItem.syque_typ_id==2){
				//多选题
				if(this.getopt(val,workItem.sthom_que_stuanswer)){
					workItem.sthom_que_stuanswer.splice(workItem.sthom_que_stuanswer.indexOf(val),1)
				}else{
					workItem.sthom_que_stuanswer.push(val)
				}
			}
			this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex]=workItem
			this.setGroupworkItem(workItem)
			this.$forceUpdate();
		},
		//大题切换上一题
		priorPap(){
			if(this.homeworkItem.syque_typ_id!=14){
				// 非组合提的直接提交
				this.submitItem(this.homeworkItem,()=>{
					// 切换上一题
					this.changePrior()
				});
			}else{
				//组合提可以跳过
				// 切换上一题
				this.changePrior()
			}
		},
		//大题切换下一题
		nextPap(){
			if(this.homeworkItem.syque_typ_id!=14){
				// 非组合提的直接提交
				this.submitItem(this.homeworkItem,()=>{
					// 切换下一题
					this.changeNext()
				});
			}else{
				//组合提提交并切换下一大题
				this.submitItem(this.groupworkItem,()=>{
					// 切换下一大题
					this.changeNext()
				});
			}
		},	
		//小题切换上一题
		grouPriorPap(){
			this.submitItem(this.groupworkItem,()=>{
				// 切换上一题
				this.changeGrouPrior()
			});
		},
		//小题切换下一题
		grouNextPap(){
			this.submitItem(this.groupworkItem,()=>{
				// 切换下一题
				this.changeGrouNext()
			});
		},
		// 提交试卷
		nextPapSubmit(){
			if(this.homeworkItem.syque_typ_id!=14){
				// 判断最后一题普通类型提交
				this.submitItem(this.homeworkItem,()=>{
					// 切换下一题
					this.changeNext()
				});
			}else{
				// 提交组合题答案，并展示出所有题型类表
				this.submitItem(this.groupworkItem,()=>{
					//显示题列表
					this.showAnswer=true
				});
				
			}
		},
		//页面大题切换上一题
		changePrior(){
			//判断大题还有上一题  true有上一题
			if(this.papIndex>0){
				//题类型+1
				this.papIndex=this.papIndex-1
				//已经是第一个题了，判断上一个大类题型  不是组合提的情况下
				if(this.homework_list[this.papIndex].syque_typ_id!=14){
					//替换值
					this.setHomeworkItem(this.homework_list[this.papIndex])
				}else{
					//小题下标重置
					this.grouTopicIndex=0
					//替换值
					this.setHomeworkItem(this.homework_list[this.papIndex])
					this.setGroupworkItem(this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex])
				}
				this.getPapNumber()	
			}else{
				//组合提填充
				this.$message({
					message: '没有上一题了',
					type: 'warning'
				});
			}
		},
		//页面大题切换下一题
		changeNext(){
			//判断下一个类型是否也是最后一个 true还有下一个类型
			if(this.homework_list.length>this.papIndex+1){
				//题类型+1
				this.papIndex=this.papIndex+1
				//判断是否是组合提
				if(this.homework_list[this.papIndex].syque_typ_id!=14){
					//替换值
					this.setHomeworkItem(this.homework_list[this.papIndex])
				}else{
					//组合提填充
					//小题下标重置
					this.grouTopicIndex=0
					this.setHomeworkItem(this.homework_list[this.papIndex])
					this.setGroupworkItem(this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex])
				}
				this.getPapNumber()
			}else{
				//最后一题
				this.showAnswer=true
			}
		},
		
		//页面小题切换上一题
		changeGrouPrior(){
			//小题是否是第一个  true还有上一小题
			if(this.grouTopicIndex>0){
				//回到上一小题
				// 切换小题
				//还有上一题
				this.grouTopicIndex=this.grouTopicIndex-1
				this.setGroupworkItem(this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex])
			}else{
				this.changePrior()
			}
		},
		//页面小题切换下一题
		changeGrouNext(){
			// 判断组合提里面的大题里面的小题是否还有下一题 true还有下一小题
			if(this.homework_list[this.papIndex].childrenQuestion.length>this.grouTopicIndex+1){
				// 切换小题
				//还有下一题
				this.grouTopicIndex=this.grouTopicIndex+1
				this.setGroupworkItem(this.homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex])
			}else{
				this.changeNext()
			}
		},
		//清空单题的答案，切换下一题的时候input框不带刷新功能
		setHomeworkItem(homeworkItem){
			if(homeworkItem.sthom_que_stuanswer&&homeworkItem.sthom_que_stuanswer.length<1&&homeworkItem.syque_typ_id!=2){
				if(homeworkItem.items&&homeworkItem.items.length>0){
					homeworkItem.items.map((iten,index)=>{
						homeworkItem.sthom_que_stuanswer[index]=homeworkItem.sthom_que_stuanswer[index]?homeworkItem.sthom_que_stuanswer[index]:""
					})
				}
				if(homeworkItem.question_item&&homeworkItem.question_item.length>0){
					homeworkItem.question_item.map((iten,index)=>{
						homeworkItem.sthom_que_stuanswer[index]=homeworkItem.sthom_que_stuanswer[index]?homeworkItem.sthom_que_stuanswer[index]:""
					})
				}
			}
			this.homeworkItem=homeworkItem
		},
		//小题清空单题的答案，切换下一题的时候input框不带刷新功能
		setGroupworkItem(groupworkItem){
			// 题型为多项填空的
			if(groupworkItem.items&&groupworkItem.items.length>0&&groupworkItem.syque_typ_id==4){
				groupworkItem.items.map((iten,index)=>{
					groupworkItem.sthom_que_stuanswer[index]=groupworkItem.sthom_que_stuanswer[index]?groupworkItem.sthom_que_stuanswer[index]:""
				})
			}
			// 题型为单项填空的
			if(groupworkItem.question_item&&groupworkItem.question_item.length>0&&(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)){
				groupworkItem.question_item.map((iten,index)=>{
					groupworkItem.sthom_que_stuanswer[index]=groupworkItem.sthom_que_stuanswer[index]?groupworkItem.sthom_que_stuanswer[index]:""
				})
			}
			this.groupworkItem=groupworkItem
		},
		//提交答题
		async submitItem(homework,fn){
			let sthom_que_stuanswer=homework.sthom_que_stuanswer
			let sthom_que_stuanswer_image=homework.sthom_que_stuanswer_image?homework.sthom_que_stuanswer_image:""
			//有数组
			let obj={
				sthom_wor_id:this.sthom_wor_id,
				sthom_que_id:homework.sthom_que_id,
				sthom_que_stuanswer:sthom_que_stuanswer,
				sthom_que_stuanswer_image:sthom_que_stuanswer_image,
			}
			this.submitloading=true
			let ref =await do_answers(obj)
			if(ref.status==200){
				fn && typeof fn == 'function' && fn()
			}else{
				this.$message.error('提交失败，请重试');
			}
			this.submitloading=false
		},
		//判断试题是否选中 answer答案  list
		getopt(answer,list){
			if(answer&&list&&list.length>0){
				return list.includes(answer)
			}else{
				return false
			}
		},

		/**
		 * 图片上传成功
		 */
			uploadImgSuccess({ data, el },type) {
			if (this.$isEmpty(data)) {
				return this.$message.error(msg);
			}
			let homework_list=this.homework_list
			let homeworkItem=this.homeworkItem
			let groupworkItem = this.groupworkItem
			if(type==1){
				homeworkItem.sthom_que_stuanswer_image=data.file
				homework_list[this.papIndex]=homeworkItem
				this.setHomeworkItem(homeworkItem)
				this.homework_list=homework_list
			}else{
				groupworkItem.sthom_que_stuanswer_image=data.file
				homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex]=groupworkItem
				this.setGroupworkItem(groupworkItem)
				this.homework_list=homework_list
			}
			this.$refs.imgUploader.uploadFiles.splice(0, 1);
			this.$forceUpdate()
		},
		//删除图片 type 1大题 2小题
		deleteImg(type){
			let homework_list=this.homework_list
			let homeworkItem=this.homeworkItem
			let groupworkItem = this.groupworkItem
			if(type==1){
				homeworkItem.sthom_que_stuanswer_image=""
				homework_list[this.papIndex]=homeworkItem
				this.setHomeworkItem(homeworkItem)
			}else{
				groupworkItem.sthom_que_stuanswer_image=""
				homework_list[this.papIndex].childrenQuestion[this.grouTopicIndex]=groupworkItem
				this.setGroupworkItem(groupworkItem)
			}
			this.homework_list=homework_list
			this.$forceUpdate()
		},


		//学生是否答题，列表显示状态
		getAnswer(item){
			let pass=true
			if(item.syque_typ_id==14){
				// 判断组合提里面的结果
				item.childrenQuestion.map(item2=>{
					let sthom_que_stuanswer=item2.sthom_que_stuanswer
					let sthom_que_stuanswer_image=item2.sthom_que_stuanswer_image
					if(sthom_que_stuanswer&&sthom_que_stuanswer.length>0&&sthom_que_stuanswer_image.length<1){
						sthom_que_stuanswer.map(item=>{
							if(item==""||item.length==0){
								pass=false
							}
						})
					}else if(sthom_que_stuanswer_image&&sthom_que_stuanswer_image.length>0){
						//有图片
						pass = true
					}else{
						pass=false
					}
				})
			}else if(item.syque_typ_id==2){
				// 判断多选题里面的结果是否有选择的
				let sthom_que_stuanswer=item.sthom_que_stuanswer
				if(sthom_que_stuanswer&&sthom_que_stuanswer.length>0){
					if(sthom_que_stuanswer.filter(function(item2,index,arr){return item2!="";}).length==0){
						pass=false
					}
				}else{
					pass=false
				}
			}else{
				let sthom_que_stuanswer=item.sthom_que_stuanswer
				//有图片
				let sthom_que_stuanswer_image=item.sthom_que_stuanswer_image
				if(sthom_que_stuanswer&&sthom_que_stuanswer.length>0&&sthom_que_stuanswer_image.length<1){
					sthom_que_stuanswer.map(item2=>{
						if(item2==""||item2.length==0){
							pass=false
						}
					})
				}else if(sthom_que_stuanswer_image&&sthom_que_stuanswer_image.length>0){
					//有图片
					pass = true
				}else{
					pass=false
				}
			}
			return pass
		},
    },
}
</script>